<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <form id="container">
        <img :src="require('@/assets/images/logo.png')" />
        <ion-item>
          <ion-label position="fixed">Identifiant</ion-label>
          <ion-input
            v-model="v$.form.username.$model"
            :disabled="isLoading === true"
            placeholder="Veuilleur saisir votre identifiant"
          ></ion-input>
        </ion-item>
        <ion-item class="margin-bottom-32">
          <ion-label position="fixed">Mot de passe</ion-label>
          <ion-input
            v-model="v$.form.password.$model"
            :disabled="isLoading === true"
            placeholder="Veuillez saisir votre mot de passe"
            type="password"
          ></ion-input>
        </ion-item>

        <ion-button
          v-if="isLoading === false"
          :disabled="v$.form.$invalid"
          type="submit"
          @click="login"
        >
          Se connecter
        </ion-button>
        <ion-spinner v-else name="crescent"></ion-spinner>
      </form>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonInput,
  IonLabel,
  IonItem,
  IonButton,
  IonSpinner,
  useIonRouter,
} from "@ionic/vue";
import { defineComponent } from "vue";
import http from "@/services/http.service";
import storage from "@/services/storage.service";
import useVuelidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import { lockClosedOutline } from "ionicons/icons";

export default defineComponent({
  name: "Login",
  components: {
    IonContent,
    IonPage,
    IonInput,
    IonLabel,
    IonItem,
    IonButton,
    IonSpinner,
  },
  setup() {
    return { v$: useVuelidate(), router: useIonRouter() };
  },
  created() {},
  mounted() {},
  data() {
    return {
      isLoading: false,
      form: {
        username: null,
        password: null,
      },
    };
  },
  validations() {
    return {
      form: {
        username: {
          required,
        },
        password: {
          required,
          min: minLength(6),
        },
      },
    };
  },
  methods: {
    login(e) {
      this.isLoading = true;

      this.$store.dispatch("global/setAuthCredentials", {
        username: this.form.username,
        password: this.form.password,
      });

      http
        .auth()
        .then(() => {
          this.$store.dispatch("global/setAuth", true);

          // Save credtention to session
          storage.setItem(
            "user",
            JSON.stringify({
              username: this.form.username,
              password: this.form.password,
            })
          );

          this.router.push("/home");
        })
        .catch(() => {
          this.$store.dispatch("global/setAuth", false);
          this.$store.dispatch("global/unsetAuthCredentials");

          storage.removeItem("user");

          this.openToast("Authentication failed", "danger", lockClosedOutline);
        })
        .finally(() => {
          this.isLoading = false;
        });

      e.preventDefault();
      e.stopPropagation();

      return false;
    },
  },
});
</script>

<style scoped lang="scss">
#container {
  text-align: center;
  position: absolute;
  left: 50%;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 640px;
}

.margin-bottom-32 {
  margin-bottom: 32px;
}
</style>
